import { addClass, removeClass } from "../helpers/DOM/classes";

const CLASS_HIDDEN = 'hidden';

export default function initTabs() {
  document.querySelectorAll('[data-component="tabs"]').forEach((tabEl) => {
    const tabBodies = tabEl.querySelectorAll('[data-tabs] [data-tab]');

    tabBodies.forEach((tabBody) => {
      addClass(tabBody, CLASS_HIDDEN);
    });

    const tabControllers = tabEl.querySelectorAll('[data-tabs-controllers] [data-tab]');

    if (!tabControllers.length) {
      console.warn('Tab controllers not found');
      return;
    }

    tabControllers.forEach((tabController) => {
      tabController.addEventListener('click', function (e) {
        e.preventDefault();

        const currTab = tabController.getAttribute('data-tab');
        const foundClickedTabBody = Array.from(tabBodies).find((tabBody) => tabBody.getAttribute('data-tab') === currTab);

        if (!foundClickedTabBody) {
          console.warn('Corresponding tab body does not exist');
          return;
        }

        tabBodies.forEach((tabBody) => {
          addClass(tabBody, CLASS_HIDDEN);
        });

        removeClass(foundClickedTabBody, CLASS_HIDDEN);

        // show active tab
        tabControllers.forEach((_tabController) => {
          removeClass(_tabController, 'bg-primary');
          removeClass(_tabController, 'text-white');
          addClass(_tabController, 'text-primary');
          addClass(_tabController, 'hover:bg-primary/10');
        });
        
        addClass(tabController, 'bg-primary');
        addClass(tabController, 'text-white');
        removeClass(tabController, 'hover:bg-primary/10');
      });
    });

    const event = new Event('click');
    tabControllers[0].dispatchEvent(event);
  });
}