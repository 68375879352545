/**
 *
 * * import all shared code here
 *
*/

// import "./scss/tailwind.css";

import "./scss/tailwind/base.css";
import "./scss/tailwind/components.css";

import "./scss/_root.scss";
import "./scss/components/dropzone.css"

import "./scss/theme/_custom.scss";
import "./scss/theme/_buttons.scss";
import "./scss/theme/_forms.scss";
import "./scss/theme/_components.scss";
import "./scss/theme/_dropdown.scss";
import "./scss/theme/_modal.scss";
import "./scss/theme/_toast.scss";
import "./scss/theme/_alerts.scss";
import "./scss/theme/_breadcrumb.scss";
import "./scss/theme/_avatars.scss";
import "./scss/theme/_accordion.scss";
import "./scss/theme/_cards.scss";
import "./scss/theme/_pagination.scss";
import "./scss/theme/_tables.scss";
import "./scss/theme/_tooltip.scss";
import "./scss/theme/_progress.scss";
import "./scss/theme/_datatable.scss";

import "./scss/theme/layout/_header.scss";
import "./scss/theme/layout/_vertical.scss";
import "./scss/theme/layout/_horizontal.scss";
import "./scss/tailwind/utilities.css";
// import "./scss/theme/_custom.scss"; // ! gives error


import "./scss/components/tabulator.min.css";

// import "feather-icons/dist/feather";
import feather from "feather-icons";
import initTabs from "./components/tabs";
import Sidebar from "./components/sidebar";
import initBreadcrumb from "./components/breadcrumb";

// import { Tabulator } from "./components/tabulator_esm";
import {
  TabulatorFull as Tabulator
} from 'tabulator-tables';
import Swiper from "swiper";
import "swiper/swiper-bundle.css"
import "./scss/plugins.scss"
import './scss/components/multiple-select.css'
import "preline/dist/preline"
import Choices from "./assets/choices.js/public/assets/scripts/choices"
import "./assets/choices.js/public/assets/styles/choices.min.css"
import { logoutUser } from "./services/auth"

window.onload = function() {
  window.featherReplace= feather.replace
  window.Choices = Choices
  feather.replace();

  initTabs();
  initBreadcrumb();

  // new Sidebar({
  //   sidebarId: 'main-sidebar',
  //   menuItemClass: '.side-menu__item',
  //   menuItemActiveClass: '.active',
  // });

  window.Tabulator = Tabulator;
  window.Swiper = Swiper;

  doLogoutOnClicked()
}

function doLogoutOnClicked() {
  const logoutButtons = document.querySelectorAll('[data-logout-button]');

  logoutButtons.forEach((logoutButton) => {
    logoutButton.addEventListener('click', (ev) => {
      ev.preventDefault();

      logoutUser().then((response) => {
        if (response) {
          window.location.href = "/"
        }
      })
    });
  });
}
