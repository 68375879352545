export default function initBreadcrumb() {
  const components = document.querySelector('[data-component="breadcrumb"]');
  if (!components) return;

  const paths = location.pathname.split('/');

  const textContent = paths.map((value, index) => {
    if (index !== 0) {
      return value.toUpperCase().split('-').join(' ');
    }
  })

  components.textContent = textContent.join(' / ');
}